import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"https://connect.facebook.net/de_DE/sdk.js#xfbml=1&appId=272376829538322&version=v2.0\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <Header></Header>
        <div id="meganav">
          <div className="container">
            {" "}
            <a
              href="/"
              title="bebe Zartpflege®"
              target="_self"
              className="navbar-brand"
            >
              <em>
                bebe Zartpflege<sup>®</sup>
              </em>
            </a>
            <div className="close-meganav" />
            <div className="col-xs-12 meganav-top">
              {" "}
              <a
                href="/"
                target="_self"
                title="Start"
                className="navitem"
              >
                Start
              </a>{" "}
              <a
                href="/menu/kontakt/"
                target="_self"
                title="Kontakt"
                className="navitem"
              >
                Kontakt
              </a>{" "}
              <a
                href="/menu/faq/"
                target="_self"
                title="FAQ"
                className="navitem"
              >
                FAQ
              </a>{" "}
              <a
                href="/menu/impressum/"
                target="_self"
                title="Impressum"
                className="navitem"
              >
                Impressum
              </a>{" "}
              <a
                href="/menu/rechtliche-hinweise/"
                target="_self"
                title="Rechtliche Hinweise"
                className="navitem"
              >
                Rechtliche Hinweise
              </a>{" "}
              <a
                href="/menu/cookie-information/"
                target="_self"
                title="Cookies"
                className="navitem"
              >
                Cookies
              </a>{" "}
              <a
                href="/menu/allg-gewinnspielbedingungen/"
                target="_self"
                title="Allg. Gewinnspielbedingungen"
                className="navitem"
              >
                Allg. Gewinnspielbedingungen
              </a>{" "}
            </div>
            <div id="col-products" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/produkte/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.produkte.png"
                  alt="Produkte"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/produkte/"
                    target="_self"
                    title="Produkte"
                  >
                    Produkte
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/produkte/#ZartcremeinderDose"
                        target="_self"
                      >
                        Zartcreme
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#schaumbad-und-dusche"
                        target="_self"
                      >
                        Schaumbad und Dusche
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Glanzshampoo"
                        target="_self"
                      >
                        Glanzshampoo
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Pflegelotion"
                        target="_self"
                      >
                        Pflegelotion
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#MildeHandseife"
                        target="_self"
                      >
                        Milde Handseife
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#shampoo"
                        target="_self"
                      >
                        Stärkendes Shampoo und Spülung
                      </a>
                    </li>
                    <li>
                    <a
                      href="/produkte/#Lippenpflegemit"
                      target="_self"
                    >  
                     Lippenpflege mit fruchtigem Kirschduft
                    </a>
                </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-unsere-maedels"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/unsere-maedels/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.unsere-maedels.png"
                  alt="Unsere Mädels"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/unsere-maedels/"
                    target="_self"
                    title="Unsere Mädels"
                  >
                    Unsere Mädels
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/unsere-maedels/#BibiBlubberblase"
                        target="_self"
                      >
                        Bibi Blubberblase
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#MiaMorgentau"
                        target="_self"
                      >
                        Mia Morgentau
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#PaulaPerlensanft"
                        target="_self"
                      >
                        Paula Perlensanft
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#ViviWallemaehne"
                        target="_self"
                      >
                        Vivi Wallemähne
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#SophieSonnenschein"
                        target="_self"
                      >
                        Sophie Sonnenschein
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#Malbuch"
                        target="_self"
                      >
                        Mal- &amp; Rätselbuch
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="col-marken" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/markenhistorie/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.markenhistorie.png"
                  alt="Markenhistorie"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/markenhistorie/"
                    target="_self"
                    title="Markenhistorie"
                  >
                    Markenhistorie
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a href="/markenhistorie/#60er" target="_self">
                        60er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#70er" target="_self">
                        70er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#80er" target="_self">
                        80er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#90er" target="_self">
                        90er
                      </a>
                    </li>
                    <li>
                      <a
                        href="/markenhistorie/#2000er"
                        target="_self"
                      >
                        2000er
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-limited-editions"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/limited-editions/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.limited_editions.png"
                  alt="Limited Editions"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/limited-editions/"
                    target="_self"
                    title="Limited Editions"
                  >
                    Limited Editions
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/limited-editions/#OnlineGame"
                        target="_self"
                      >
                        Online Game
                      </a>
                    </li>
                    <li>
                      <a
                        href="/limited-editions/#LimitedEditions"
                        target="_self"
                      >
                        Limited Editions
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 meganav-mobile-bottom">
              {" "}
              <a
                className="navitem"
                title="Start"
                target="_self"
                href="/"
              >
                Start
              </a>{" "}
              <a
                className="navitem"
                title="Kontakt"
                target="_self"
                href="/menu/kontakt/"
              >
                Kontakt
              </a>{" "}
              Newsletter{" "}
            </div>
          </div>
        </div>
        <div id="content">
          <div className="container">
            <div id className="row stageless mb-50">
              <div className="text-center col-sm-8 col-sm-offset-2">
                <h1>FAQ</h1>
                <p>
                  Du hast Fragen? In unseren FAQ findest du Wissenswertes zu{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  und Antworten zu häufig gestellten Fragen.
                </p>
              </div>
            </div>
            <div id className="row">
              <div className=" col-sm-8 col-sm-offset-2">
                <div className="accordion">
                  <h3>
                    Wo kann ich die Produkte von{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    kaufen?
                  </h3>
                  <div>
                    <p>
                      Die Produkte von{" "}
                      <strong>
                        bebe Zartpflege<sup>®</sup>
                      </strong>{" "}
                      sind in nahezu jeder Drogerie und in den
                      <br />
                      Fachabteilungen der SB-Warenhäuser erhältlich. Eine
                      Bestellung über einen Online-Shop ist ausserdem auf
                      unserer Seite direkt neben deinem{" "}
                      <span className="Kein_Umbruch">
                        gewünschten Produkt möglich.
                      </span>
                      <span className="Kein_Umbruch">
                        <span className="Kein_Umbruch" />
                      </span>
                    </p>
                  </div>
                  <h3>Kann ich Produkte ausprobieren, bevor ich sie kaufe?</h3>
                  <div>
                    <p>
                      Die Probenauswahl bei{" "}
                      <strong>
                        bebe Zartpflege<sup>®</sup>
                      </strong>{" "}
                      variiert immer wieder und uns stehen nur begrenzt Proben
                      zur Verfügung. Aber wir beraten dich gerne telefonisch –
                      so können wir dir die Auswahl bestimmt ein wenig
                      erleichtern. Dafür erreichst du uns unter der kostenlosen
                      Nummer 00800 555 22 000.
                    </p>
                  </div>
                  <h3>
                    Für welches Alter ist{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    geeignet?
                  </h3>
                  <div>
                    <p>
                      <strong>
                        bebe Zartpflege<sup>®</sup>
                      </strong>{" "}
                      wurde für die tägliche Pflege von Babys und Kleinkindern
                      entwickelt und ist auf die Bedürfnisse zarter Haut
                      abgestimmt, um die empfindliche Haut der Jüngsten weich
                      und babysanft zu halten. Aber nicht nur für die Kleinen
                      ist{" "}
                      <strong>
                        bebe Zartpflege<sup>®</sup>
                      </strong>{" "}
                      geeignet. Auch viele Erwachsene verwenden die Produkte
                      regelmäßig – für zarte, gepflegte Haut.{" "}
                    </p>
                  </div>
                  <h3>
                    Sind in{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    tierische Inhaltsstoffe enthalten?
                  </h3>
                  <div>
                    <p>
                      In der{" "}
                      <strong>
                        bebe<sup>®</sup>
                      </strong>{" "}
                      Zartcreme ist Lanolin (Wollfett vom Schaf) enthalten. Alle
                      weiteren Produkte aus unserem{" "}
                      <strong>
                        bebe Zartpflege<sup>®</sup>
                      </strong>{" "}
                      Sortiment sind frei von tierischen Inhaltsstoffen.
                    </p>
                  </div>
                  <h3>
                    Warum ist in{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    Paraffin enthalten?
                  </h3>
                  <div>
                    <p>
                      Unser Ziel ist es, besonders hautverträgliche
                      Pflegeprodukte zu entwickeln. Paraffinöl hat eine hohe
                      pharmazeutische Qualität und ist nicht allergen.
                      Dermatologen und Allergologen empfehlen deshalb oft
                      hochreine Paraffinöle zur Pflege von empfindlicher und zu
                      Allergien neigender Haut.
                    </p>
                  </div>
                  <h3>
                    Enthält{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    Alkohol?
                  </h3>
                  <div>
                    <p>
                      Alkohol ist die Gruppenbezeichnung für
                      Hydroxylverbindungen von verschiedenen
                      Kohlenwasserstoffen. Die allgemeine Bezeichnung für
                      Alkohol ist Ethanol. Zur gleichen Gruppe gehören auch
                      Methanol, Propanol und Butanol. Ethanol ist der
                      Trinkalkohol, chemisch C<sub>2</sub>H<sub>5</sub>0H.
                      Dieser einwertige Alkohol wirkt, eingesetzt in höheren
                      Konzentrationen, austrocknend auf die Haut sowie
                      desinfizierend und konservierend. In dieser Form ist kein
                      Alkohol in der{" "}
                      <strong>
                        bebe<sup>®</sup>
                      </strong>{" "}
                      Zartpflege Produkten enthalten. Außer diesem Alkohol, der
                      in bestimmten Produkten zur Pflege unreinen Haut und
                      einigen Deodorants enthalten ist, setzen wir noch andere
                      Alkohole ein, wie z.B. Cetearylalkohol. Dieser gehört zur
                      Gruppe der Fettalkohole. Dies sind langkettige Alkohole
                      mit 8-18 Kohlenstoffatomen. Sie besitzen keine der oben
                      genannten Eigenschaften, sondern erfüllen verschiedene
                      Funktionen in kosmetischen Produkten. Sie dienen z.B. als
                      Emulgator, Konsistenzgeber oder Stabilisator.
                    </p>
                  </div>
                  <h3>
                    Ist{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    komedogen?
                  </h3>
                  <div>
                    <p>
                      Jeder Hauttyp reagiert anders, daher kann eine mögliche
                      komedogene Wirkung von kosmetischen Inhaltsstoffen nicht
                      grundsätzlich vollständig ausgeschlossen werden. Alle{" "}
                      <strong>
                        bebe Zartpflege<sup>®</sup>
                      </strong>{" "}
                      Produkte werden bezüglich ihrer Hautverträglichkeit
                      dermatologisch getestet. Bisher liegen uns keine
                      Erkenntnisse zu einer komedogenen Wirkung von{" "}
                      <strong>
                        bebe Zartpflege<sup>®</sup>
                      </strong>{" "}
                      Produkten vor.
                    </p>
                  </div>
                  <h3>
                    {" "}
                    Wie lange ist{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    haltbar?
                  </h3>
                  <div>
                    <p>
                      Grundsätzlich sind die meisten Kosmetikprodukte nach
                      Öffnung mindestens 30 Monate lang haltbar. Wird dieser
                      Zeitraum unterschritten, muss auf der Verpackung ein
                      Mindesthaltbarkeitsdatum (abgekürzt mit MHD oder EXP)
                      genannt werden. Andernfalls wirst Du auf der Verpackung
                      die Abbildung eines kleinen Cremetiegels finden, auf der
                      die Haltbarkeit nach Anbruch in Monaten – zum Beispiel 12
                      oder 36 Monate – angegeben ist.
                    </p>
                  </div>
                  <h3>
                    Warum enthalten{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    Produkte Konservierungsstoffe? Sind diese schädlich?
                  </h3>
                  <div>
                    <p>
                      Konservierungsstoffe sind ein wesentlicher Bestandteil von
                      Körperpflegeprodukten, die Wasser enthalten. Ohne sie
                      können Produkte des täglichen Bedarfs, auf die du dich
                      verlässt, innerhalb weniger Wochen oder sogar Tage
                      verderben. Wasserhaltige Produkte mit dem Hinweis „ohne
                      Konservierungsstoffe" sind nicht notwendigerweise besser
                      oder sicherer, sondern mit Stoffen konserviert, die nicht
                      in der EU-Kosmetikverordnung gelistet sind, aber noch eine
                      andere Funktion in dem Produkt haben. Konservierungsstoffe
                      gewährleisten die Produktsicherheit vor und während der
                      Verwendung.
                    </p>
                    <p>
                      Jeder Konservierungsstoff muss unseren
                      Qualitätssicherungsprozess durchlaufen, bevor er in eines
                      unserer Produkte gelangen darf. Daher liegen die Mengen an
                      Konservierungsstoffen, die wir in unseren Produkten
                      zulassen, weit unter den von den Regulierungsbehörden
                      gesetzten Grenzwerten.
                    </p>
                  </div>
                  <h3>
                    Warum enthalten{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    Produkte Mineralöle und Silikone?{" "}
                  </h3>
                  <div>
                    <p>
                      Mineralöle werden seit über 100 Jahren zur Herstellung von
                      Arzneimitteln und Kosmetika sicher verwendet. Das in
                      kosmetischen Produkten eingesetzte Paraffinum Liquidum
                      beispielsweise ist von hoher medizinischer bzw.
                      pharmazeutischer Qualität und zeichnet sich durch eine
                      besonders gute Verträglichkeit aus. Es wird auch für die
                      zu Allergien neigende Haut empfohlen.
                      Unverträglichkeitsreaktionen auf diesen Inhaltsstoff sind
                      nicht bekannt. Die von uns verwendete Qualität entspricht
                      der Europäischen Pharmakopöe (EAB), dem Qualitätsstandard
                      für Arzneimittel und wird dementsprechend standardmäßig
                      dort eingesetzt.
                    </p>
                    <p>
                      In der Hautpflege sorgen Silikone für einen
                      wasserabweisenden Schutzfilm, der vor Feuchtigkeitsverlust
                      schützt. Silikone sorgen für eine geschmeidige Konsistenz
                      des Produktes und langandauernden Halt. Aus
                      gesundheitlichem Blickwinkel besteht für die Anwendung
                      silikonhaltiger kosmetischer Produkte keine
                      Beeinträchtigung – allergische Reaktionen auf Silikone
                      sind bisher nicht bekannt.
                    </p>
                  </div>
                  <h3>
                    Warum enthalten{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    Produkte Parabene?{" "}
                  </h3>
                  <div>
                    <p>
                      Parabene sind sehr gut verträgliche Konservierungsstoffe,
                      die in vielen Nahrungsmitteln, Arzneimitteln und Kosmetika
                      Verwendung finden, um die mikrobiologische
                      Unbedenklichkeit dieser Produkte zu gewährleisten. Sie
                      schützen die Produkte vor einer Vielzahl von Bakterien und
                      Pilzen, die zu Hautirritationen und Infektionen führen
                      können. Parabene kommen auch natürlicherweise z.B. in Soja
                      und Heidelbeeren vor.
                    </p>
                    <p>
                      Angesichts der wissenschaftlichen Bewertungen der für
                      kosmetische Produkte zugelassenen Parabene dieser
                      Stoffgruppe und der langjährigen sicheren Verwendung durch
                      Konsumenten haben wir volles Vertrauen in die Sicherheit
                      und Unbedenklichkeit der Parabene, die vom
                      wissenschaftlichen Komitee der Europäischen Kommission
                      (SCCS) erst im letzten Jahr neu bewertet wurden.
                    </p>
                  </div>
                  <h3>
                    Werden{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    Produkte mithilfe von Tierversuchen getestet?
                  </h3>
                  <div>
                    <p>
                      {" "}
                      Bei bebe® sind wir bestrebt, Tierversuche in der Entwicklung von Kosmetik‐ und Körperpflegeprodukten zu beenden. Wir legen großen Wert auf die Integrität unserer Produkte und unseres Handelns. Wenn es um das Wohlergehen von Tieren geht, teilen wir Ihre Bedenken und sind der Meinung, dass das Testen von Kosmetik‐ und Körperpflegeprodukten an Tieren enden sollte. Aus diesem Grund führen wir weltweit keine Tierversuche für Kosmetik‐,Körperpflegeprodukte oder deren Inhaltsstoffe durch, außer in seltenen Fällen, in denen dies gesetzlich vorgeschrieben ist oder es noch keine wissenschaftlich fundierte Alternative gibt.
                    </p>
                    <p>
                    Die Welt ist noch nicht frei von Tierversuchen für Kosmetika, aber wir von der Kenvue sind stolz darauf, unseren Teil zur Lösung beizutragen. Seit vielen Jahren arbeiten wir weltweit mit führenden Forschungseinrichtungen zusammen, um die Entwicklung und Förderung alternativer Testtechnologien zur Überprüfung der Sicherheit unserer Kosmetikprodukte voran zu treiben. Wir werden dies auch weiterhin tun, da wir davon überzeugt sind, dass dieser Schritt letztendlich zur weltweiten Abschaffung von Tierversuchen führen wird.
                    </p>
                    <p>
                    Wir bemühen uns immer, in dieser wichtigen Angelegenheit bessere Ergebnisse zu erzielen, und wir freuen uns über Ihr Engagement zu diesem wichtigen Thema. Weitere Informationen zu unseren Tierschutz‐ und Testrichtlinien für Kosmetika und Körperpflegeprodukte finden Sie auf unserer Website unter <a href="https://www.kenvue.com/our-commitments" target="_blank">https://www.kenvue.com/our-commitments</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          src="/fileadmin/js/vendor/jquery6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/detectmobilebrowser6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/bootstrap.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.ba-throttle-debounce.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/skrollr.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/isMobile.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.scrollTo.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/script71c5.js?1527068951"
          type="text/javascript"
        />
      </div>
    );
  }
}

export default Page;
